<div class="container-fluid">
    <div class="row">
        <div class="col toolbar">
            <div class="d-flex inner-toolbar">
                <form>
                    <button role="button" class="btn btn-default mr-2" [class.btn-primary]="tool === 'brush'" (click)="setBrush()" title="Brush Tool"><i class="fas fa-paint-brush"></i></button>
                    <!-- <button role="button" class="btn btn-default mr-2" [class.btn-primary]="tool === 'eraser'" (click)="setEraser()" title="Eraser Tool"><i class="fas fa-eraser"></i></button> -->
                    <button role="button" class="btn btn-default mr-2" (click)="undo()" title="Undo Annotation" [disabled]="scribblePad?.toData()?.length < 1 || tool === 'eraser'"><i class="fas fa-undo"></i></button>
                    <button role="button" class="btn btn-default mr-2" (click)="redo()" title="Redo Annotation" [disabled]="history?.length < 1 || tool === 'eraser'"><i class="fas fa-redo"></i></button>
                    <button role="button" class="btn btn-default mr-2" (click)="clear()" title="Clear Annotation"><i class="fas fa-sync-alt"></i></button>
                    <button role="button" class="btn btn-default mr-2" (click)="uploader.click()" title="Upload Image"><i class="fas fa-image"></i></button>
                </form>
                <div class="divider"></div>
                <div class="d-flex flex-column flex-md-row">
                    <div class="d-flex align-items-center mr-3">
                        <div class="mr-3 bold">Size</div>
                        <input type="number" id="pen-size" class="pen-size form-control" min="1" title="Pen size" value="2" (change)="setSize($event.target.value)">
                    </div>
                    <div class="d-flex align-items-center mt-2 mt-md-0">
                        <div class="mr-3 bold">Color</div>
                        <p-colorPicker (onChange)="setColor($event)" [(ngModel)]="penColor"></p-colorPicker>
                    </div>
                    <input type='file' accept="image/*" (change)="uploadImage($event);" #uploader hidden />
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col w-100 p-0" id="annotator-container" (mousemove)="trackMouse($event)">
            <canvas *ngIf="innerWidth" id="referrence-pad" class="referrence-pad" [attr.width]="innerWidth" [attr.height]="innerWidth * heightPercent"></canvas>
            <canvas *ngIf="innerWidth" id="signature-pad" class="signature-pad" [attr.width]="innerWidth" [attr.height]="innerWidth * heightPercent" (mouseup)="drawComplete($event)" (mousedown)="drawStart($event)"></canvas>
            <img [attr.src]="reference" id="referrence-image" class="hidden">
        </div>
    </div>
</div>