<form [formGroup]="form" *ngIf="form">
    <fieldset [disabled]="processing || loading">
        <div class="row text-left">
            <div class="col-12 bold h6">
                <span class="bold-400 text-muted" for="specialty">Specialty</span>
                <b class="text-red ml-2" *ngIf="getControl('specialties')?.errors?.required">*</b>
                <app-autocomplete #specialtyComp (search)="searchSpecialties($event)" [results]="specialties" [field]="'text'" (select)="updateSpecialtyForm()" (unselect)="updateSpecialtyForm()"></app-autocomplete>
            </div>
            <div class="col-12 bold h6 py-4">
                <span class="bold-400 text-muted" for="sub-specialty">Sub - Specialty</span>
                <app-autocomplete #subspecialtyComp (search)="searchSubspecialties($event)" [results]="subspecialties" [field]="'text'" (select)="updateSubspecialtyForm()" (unselect)="updateSubspecialtyForm()"></app-autocomplete>
            </div>
            <div class="col-12 bold h6 pb-0">
                <span class="bold-400 text-muted">Hospital/Clinic Affiliations</span>
            </div>
            <div class="col-12 pb-4">
                <small for="hospital-affiliations" class="text-gray text-sm">Enter the hospitals and clinics that you are affiliated with.</small>
                <div class="form-group">
                    <app-autocomplete #hospitalComp (search)="searchHospitalMasters($event)" [results]="hospitalMasters" [field]="'facility_name'" (select)="updateHospitalForm()" (unselect)="updateHospitalForm()"></app-autocomplete>
                </div>
            </div>
            <div class="col-12 bold h6 pb-0">
                <span class="bold-400 text-muted">HMO Accreditations</span>
            </div>
            <div class="col-12 pb-4">
                <small for="hospital-accreditations" class="text-gray text-sm">Enter the HMOs that you accept.</small>
                <app-autocomplete #hmoComp (search)="searchHMOMasters($event)" [results]="hmoMasters" [field]="'hmo_text'" (select)="updateHmoForm()" (unselect)="updateHmoForm()"></app-autocomplete>
            </div>
            <div class="col-12 bold h6 pb-0">
              <span class="bold-400 text-muted">Medical Associations</span>
            </div>
            <div class="col-12 pb-4">
              <small for="hospital-accreditations" class="text-gray text-sm">Enter the medical associations you are affiliated with.</small>
              <app-autocomplete #society (search)="searchSociety($event)" [results]="societyLists" [field]="'society_description'" (select)="updateSocietyForm()"
                                (unselect)="updateSocietyForm()"></app-autocomplete>
            </div>
        </div>
    </fieldset>
</form>
