<section class="content">
  <fieldset [disabled]="savingDocument">
    <div class="pl-4 mb-3 mt-3 pr-4" *ngIf="formTemplate?.loading">
      <div class="row">
        <div class="col">
          <h3 style="display: inline">
            <p-skeleton height="50px" width="400px"></p-skeleton>
          </h3>
        </div>
        <div class="d-flex col justify-content-end">
          <p-skeleton height="50px" borderRadius="20px" width="120px" class="mr-2"></p-skeleton>
          <p-skeleton height="50px" borderRadius="20px" width="150px" class="mr-2"></p-skeleton>
          <p-skeleton height="50px" borderRadius="20px" width="120px" class="mr-2"></p-skeleton>
        </div>
      </div>
    </div>
    <div class="mb-3 mt-3 pr-4 pl-4" *ngIf="!formTemplate?.loading">
      <div class="row">
        <div class="col">
          <h3 class="text-color uppercase bold header-title" style="display: inline">
            <span *ngIf="!documentTemplate?.document_subtype?.with_custom_title">{{ documentTemplate?.name }}</span>
            <input type="text" class="form-control" *ngIf="documentTemplate?.document_subtype?.with_custom_title"
              placeholder="Enter document title" [(ngModel)]="title" (ngModelChange)="onTitleChange($event)">
            <p-skeleton height="2rem" *ngIf="!documentTemplate?.name"></p-skeleton>
          </h3>
        </div>
        <div class="col d-flex justify-content-end text-nowrap mt-lg-0 mt-3">
          <div class="med-hist-table-wrapper">
            <div class="med-hist-table-scroller d-flex">
              <div class="input-group-prepend mr-3" *ngIf="documentTemplate?.document_subtype?.can_view_pdf && document">
                <button *ngIf="document?.pdfs?.length > 1" type="button"
                  class="font-small btn btn-lg btn-light bordered-gray dropdown-toggle" data-toggle="dropdown" aria-expanded="false"
                  [disabled]="loadingPDF">
                  View PDF<i class="fa fa-print ml-2"></i>
                </button>
                <a *ngIf="document?.pdfs?.length === 1" type="button" class="font-small btn btn-lg btn-light bordered-gray"
                  [disabled]="loadingPDF" [attr.href]="document?.pdfs[0].pdf_url" target="_blank">
                  View PDF<i class="fa fa-print ml-2"></i>
                </a>
                <div class="dropdown-menu" x-placement="bottom-start"
                  style="border-radius: 20px; position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 38px, 0px);">
                  <a *ngFor="let pdf of document?.pdfs; let i = index" class="dropdown-item" [attr.href]="pdf?.pdf_url"
                    target="_blank" type="button"><i class="far fa-file-pdf mr-2"></i>{{ pdf?.name }}</a>
                </div>
                <button class="btn btn-lg btn-light bordered-gray ml-3 font-small" (click)="sendFile()">
                  {{ document?.sending ? "Sending" : "Send To Patient" }}
                  <i *ngIf="document?.sending" class="ml-2 nav-icon fas fa-circle-notch spinning"></i>
                </button>
              </div>
    
              <button class="btn btn-lg btn-primary mr-3 font-small" 
                [disabled]="formTemplate?.formGroup?.invalid" (click)="save(finalCode, finalizedCode)">
                Finalize
              </button>
              <button *ngIf="documentTemplate?.document_subtype?.can_draft" class="btn btn-lg btn-light bordered-gray mr-3 font-small"
                (click)="save(draftCode, document?.workflow_status_code || newCode)">
                Save as Draft
              </button>
              <button class="btn btn-lg btn-danger mr-3 font-small" (click)="deleteDocument()" *ngIf="document">
                Delete
              </button>
              <button class="btn btn-lg btn-light bordered-gray font-small" (click)="cancel()">
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-clinical-document-form-template #formTemplate (valueChange)="onValueChange($event)"
      [savingDocument]="savingDocument" (saveForm)="save(trackingCode, newCode)"
      [currentForm]="documentTemplate?.name"></app-clinical-document-form-template>
    <!-- <div *ngFor="let section of documentTemplate?.sections">
              <ng-container *ngIf="!either(section?.type, ['tab'])" [ngTemplateOutlet]="sectionTemplate" [ngTemplateOutletContext]="{section:section, fg: formGroup}"></ng-container>
              <ng-container *ngIf="either(section?.type,['tab'])" [ngTemplateOutlet]="tab" [ngTemplateOutletContext]="{section:section, fg: formGroup}"></ng-container>
              <div class="clearfix"></div>
          </div> -->

    <!-- <div
        class="col-sm-12"
        *ngIf="documentTemplate?.document_subtype?.can_review"
      >
        <div class="card">
          <div class="card-header">Document Assigment</div>
          <div class="card-body">
            <p>Please select the user/s who will approve the document</p>
            <p>
              <i
                >NOTE: Submitting this document will grant the user/s access to
                the document draft.</i
              >
            </p>
            <app-clinical-documentation-approver-form
              #approverForm
              [template]="documentTemplate"
              [document]="document"
            ></app-clinical-documentation-approver-form>
          </div>
        </div>
      </div> -->
  <div class="clearfix"></div>
    <div class="pl-4 mb-3 pr-4" *ngIf="formTemplate?.loading">
      <div class="row">
        <div class="d-flex col justify-content-end">
          <p-skeleton height="50px" borderRadius="20px" width="120px" class="mr-2"></p-skeleton>
          <p-skeleton height="50px" borderRadius="20px" width="150px" class="mr-2"></p-skeleton>
          <p-skeleton height="50px" borderRadius="20px" width="120px" class="mr-2"></p-skeleton>
        </div>
      </div>
    </div>
    <div class="pl-4 mb-3 pr-4 text-nowrap" *ngIf="!formTemplate?.loading">
      <div class="row">
        <div class="col d-flex justify-content-end">
          <div class="med-hist-table-wrapper">
            <div class="med-hist-table-scroller">
              <div class="input-group-prepend mr-3" style="display: inline"
                *ngIf="documentTemplate?.document_subtype?.can_view_pdf && document">
                <button *ngIf="document?.pdfs?.length > 1" type="button"
                  class="font-small btn btn-lg btn-light bordered-gray dropdown-toggle" data-toggle="dropdown" aria-expanded="false"
                  [disabled]="loadingPDF">
                  View PDF<i class="fa fa-print ml-2"></i>
                </button>
                <a *ngIf="document?.pdfs?.length === 1" type="button" class="font-small btn btn-lg btn-light bordered-gray"
                  [disabled]="loadingPDF" [attr.href]="document?.pdfs[0].pdf_url" target="_blank">
                  View PDF<i class="fa fa-print ml-2"></i>
                </a>
                <div class="dropdown-menu" x-placement="bottom-start" style="
                      border-radius: 20px;
                      position: absolute;
                      will-change: transform;
                      top: 0px;
                      left: 0px;
                      transform: translate3d(0px, 38px, 0px);
                    ">
                  <a *ngFor="let pdf of document?.pdfs; let i = index" class="dropdown-item" [attr.href]="pdf?.pdf_url"
                    target="_blank" type="button"><i class="far fa-file-pdf mr-2"></i>{{ pdf?.name }}</a>
                </div>
              </div>
              <button class="font-small btn btn-lg btn-primary mr-3"
                [disabled]="formTemplate?.formGroup?.invalid" (click)="save(finalCode, finalizedCode)">
                Finalize
              </button>
              <button *ngIf="documentTemplate?.document_subtype?.can_draft" class="font-small btn btn-lg btn-light bordered-gray mr-3"
                (click)="save(draftCode, document?.workflow_status_code || newCode)">
                Save as Draft
              </button>
              <button class="font-small btn btn-lg btn-danger mr-3" (click)="deleteDocument()" *ngIf="document">
                Delete
              </button>
              <button class="font-small btn btn-lg btn-light bordered-gray" (click)="cancel()">
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</section>

<app-clinical-document-sign-modal #signDocumentModal
  (signDocument)="save($event?.document_status_code, $event?.workflow_status_code)"></app-clinical-document-sign-modal>